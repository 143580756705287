import React, {useState, useEffect} from 'react';
import Config from '../../../config/config';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcceptStatuses from "../../Project/ui/AcceptStatuses";
import {getUserCadastreData} from '../../../state/reducers/user';
import { KataszterController } from '../../../controllers/KataszterController';
import * as olProj from 'ol/proj';
// import 'bootstrap/dist/css/bootstrap.css'; 
import ImageZoom from "react-image-zooom";
import Carousel from 'react-bootstrap/Carousel';

function MoreDetails(props) {

	const [popup, setPopup] = useState({visible: false, imageId: '', note:''});
	const [task, setTask] = useState(false);

    const [selected, setSelected] = useState({});
	const [edit, setEdit] = useState(false);
	const [data, setData] = useState([]);
	const [isTask, setIsTask] = useState(false);
	const [images, setImages] = useState([]);
	const [loadingData, setLoadingData] = useState(false); // eslint-disable-line
	const [feedback, setFeedback] = useState({message:"", color:""});
	const [liveData, setLiveData] = useState(false);

	const [zoomLevel, setZoomLevel] = useState(300);

	const handleZoomChange = (event) => {
	  setZoomLevel(Number(event.target.value));
	};

	const zoomLevels = [300, 400, 500, 600, 700, 800];
	//Static url-s from config
	// const imgURL = Config.static.img;
	const details = props.data;
	// const thumbnailURL = Config.static.imgThumbnail;
	const item_id = details?.values_?.id
	const cadastre_id = details.hasOwnProperty("cadastre_id") ? details.cadastre_id : details.values_.cadastre_id
	let form_json = cadastre_id !== undefined ? props.cadastreData[cadastre_id].form_json : false;
    const KataszterControllerInst = KataszterController.getInstance();

	useEffect(() => {
		if(feedback.message !== "") {
			setTimeout(() => {
				setFeedback({message:'', color:''});
			}, 4000)
		}
	}, [feedback])

	useEffect(() => {
		setImages([]);
		setEdit(false);
		setData([]);
		setIsTask(!props.data.values_.hasOwnProperty("cadastre_ref"));
		if(props.data.values_.images !== undefined && props.data.values_.images !== null) {
			setImages(props.data.values_.images !== undefined && props.data.values_.images !== null ? props.data.values_.images : [])
		}

		if(props.data.values_.hasOwnProperty("task")) {
			setTask(props.data.values_.task)
		}else {
			setTask(false);
		}

		const propertyValues = details.values_;
		const cadastre_values = propertyValues.hasOwnProperty("json") ? propertyValues.json : propertyValues;

		if(form_json) {
			if(typeof form_json === 'string'){
				form_json = JSON.parse(form_json)
			}
			form_json.forEach(detail => {
				let variable_name = false;

				if(detail.visible) {
					if(detail.visible.visible_details) {
						variable_name = {name:detail.name, label:detail.label, input:detail.input}
					}
				}
				if(variable_name) {
					if(cadastre_values !== null) {
						if(cadastre_values.hasOwnProperty(variable_name.name)) {
							if(cadastre_values.input === 'list') {
								setData((prevState) => ([...prevState, {...variable_name, value:cadastre_values.values[variable_name.name]}]));

							}else {
								setData((prevState) => ([...prevState, {...variable_name, value:cadastre_values[variable_name.name]}]));
							}
						}
					}
				}
			});
		}
	}, [props.data]); // eslint-disable-line

	useEffect(() => {
		if(props?.data?.values_.hasOwnProperty('json')) {
			if(props?.data?.values_?.json?.smart_device) {
				KataszterControllerInst.getLiveData(props.data.values_.ref).then(res => {
					console.log('live data response', res);
					setLiveData(res.data)
				}).catch(err => {
					console.log('Error while getting live cadastre data', err)
				});
			}
		}
	}, []) // eslint-disable-line

	let cadastre = cadastre_id !== null ? props.cadastreData[cadastre_id] : null;

	function loadData() {
		if(cadastre !== null && cadastre !== undefined) {
			return(data.map(element => { // eslint-disable-line
				if(typeof form_json === 'string'){
					form_json = JSON.parse(form_json)
				}
				let formData = form_json.find(x => (x.name === element.name));
				if(element.input !== 'image') {
					if(formData) {
						if(formData.editable && edit && !isTask) {
							return setupModifiableData(formData, element);
						}else {
							return(
								<tr key={element.name}>
									<td className="key">{element.label}:</td>
									<td>{formData.input === 'list' ? formData.values[element.value] :element.value}</td>
								</tr>
							)
						}
					}
				}
			}));
		}
		else {
			return(Object.entries(props.data.values_).map(([key, value]) => { // eslint-disable-line
				if(typeof value === 'string') {
					return(<tr key={key}><td className="key">{key}</td><td>{value}</td></tr>)
				}
			}));
		}
	}
	function updateSelected(value, id, key) {
        let temp = selected;
        if(selected.hasOwnProperty(id)) {
            if(selected[id].hasOwnProperty(key)) {
                if(value === "null" || value === "") {
                    delete temp[id][key];
                    if(!Object.keys(temp[id]).length){
                        delete temp[id];
                    }
                }else {
                    let obj = {}
                    obj[key] = value;

                    temp[id] = {
                        ...temp[id],
                        ...obj
                    }
                }
            }else {
                temp[id][key] = value
            }
        }else {
            let obj = {}
            obj[key] = value;

            temp[id] = {
                ...obj
            }
        }
        setSelected({...temp});
    }

	function getValue(key, element) {
        let value = element.value
        if(selected.hasOwnProperty(cadastre_id)) {
            if(selected[cadastre_id].hasOwnProperty(key)) {
                value = selected[cadastre_id][key]
            }
            else {
                value = element.value
            }
        }
        return value;
    }

	function setupModifiableData(formData, element) {
        if(formData.input === "string" || formData.input === "number"){
            return(
                <tr >
                    <td style={{fontSize: "11px", fontWeight:"600"}}>{formData.label}</td>
                    <td>
                        <input type={formData.input === "string" ? "text" : "number"} style={{fontSize:'11px', fontWeight:'200'}} name={formData.name} className="small form-control filter-search" placeholder="Nincs megadva" value={getValue(element.name, element)} onChange={(event) => {updateSelected(event.target.value, cadastre_id, formData.name)}}/>
                    </td>
                </tr>
            );
        }
        else if(formData.input === "list") {
            return(
                <tr >
                    <td style={{fontSize: "11px", fontWeight:"600"}} >{formData.label}</td>
                    <td>
                        <select className="form-control " style={{fontSize: '11px', margin: 0, padding: '6px', height: '100%' }} onChange={(event) => {updateSelected(event.target.value, cadastre_id, formData.name)}} value={getValue(formData.name, element)}>
                            <option value="null">---</option>
                            {Object.entries(formData.values).map(([key, value]) => {
                                return(
                                    <option value={key}>{value}</option>
                                )
                            })}
                        </select>
                    </td>
                </tr>);
        }
        else {
            return(<tr key={formData.name}><td className="key">{formData.label}:</td><td>{element.value}</td></tr>)
        }
    }


    function updateTasks() {
        setLoadingData(true);
        let updateData = selected[cadastre_id];
        let temp = {};
		if(typeof form_json === 'string'){
			form_json = JSON.parse(form_json)
		}
		form_json.filter(x => x.editable || x.required).forEach(x => {
            temp[x.name] = updateData.hasOwnProperty(x.name) ? updateData[x.name] : details.values_.json[x.name]
        });

		let coords = olProj.toLonLat(props.data.getGeometry().getCoordinates());

        KataszterControllerInst.updateKataszterById(item_id, cadastre_id, temp, coords).then(response => {
            if(response.statusCode === 200) {
                let temp = [];
                data.forEach(element => {
                    if(Object.keys(updateData).includes(element.name)) {
                        let i = element;
                        i.value = updateData[element.name]
                        temp.push(i);
                    }else {
                        temp.push(element);
                    }
                });
				setFeedback({message:"Feladatok sikeresen frissitve", color:'green'});
                setData(temp);
            }else {
                setFeedback({message:"Feladatok frissitése sikertelen, próbálja meg késöbb.", color:'red'})
            }
            setLoadingData(false);
			setEdit(false)
        }).catch(() => {
			setEdit(false);
			setLoadingData(false);
			setFeedback({message:"Feladatok frissitése sikertelen, próbálja meg késöbb.", color:'red'})
		})
    }

	function getLiveData(key, value) {
		let type = typeof value;
		if(type === "string") {
			return(
				<tr key={key}><td className="key">{key}:</td><td>{value}</td></tr>
			)
		}
		else if(type === "object") {
			let table = []
			Object.entries(value).map(([subKey, subValue]) => { // eslint-disable-line
				table.push(
					<tr key={subKey}>
					<td className="key">{key}:</td>
					<td>{subValue}</td>
				</tr>
				)
			})
			return(table)
		}
		else if(type === "boolean") {
			return(
				<tr key={key}><td className="key">{key}:</td><td>{value ? "Igen" : "Nem"}</td></tr>
			)
		}
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 h-100">
					<div className="row h-100">
						{/* <div className={props.data.info.task === undefined ? "col-md-12" : "col-md-8"}> */}
						<div className={`h-100 ${images.length && !edit ? "col-md-8" : "col-md-12"}`}>
							<div  style={{overflowY: 'auto', overflowX: 'hidden', height: '100%'}}>
								{cadastre && props.features === undefined && <div>
									<p className="cadastre-label-map-details">{cadastre.cadastre_label}</p>
								</div>}
								{!isTask &&<div style={{marginTop:'10px'}}>
									<span style={{fontSize:'12px'}} role="button" onClick={() => setEdit(!edit)}><FontAwesomeIcon style={{marginRight:'5px'}} alt="Szerkesztés" size="1x" icon={['fas', 'edit'] }/>Adatok szerkesztése</span>
								</div>}
								<div className="table-wrapper">
								{task ?
								<>
									<div className="row project-details-map">
										<p style={{width:"40%"}}><strong>Feladat:</strong> {task.id}</p>
										{/* <button onClick={() => props.zoomToFeature(feature)} ><FontAwesomeIcon size="1x" icon={['far', 'search-plus'] } />Közelit</button> */}
									</div>
									<div className="row" style={{paddingLeft:"6px", paddingBottom:"10px"}}>
										<div className="col-sm-3">
											<span>
												<span style={{paddingRight:"5px"}}><FontAwesomeIcon alt="Applikációból feltöltve" size="1x" icon={['fas', 'upload'] }/></span>
												<AcceptStatuses checked={task.app ? 1 : 0}/>
											</span>
										</div>
										<div className="col-sm-3">
											<span>
												<span style={{paddingRight:"5px"}}><FontAwesomeIcon  alt="Applikációból feltöltve" size="1x" icon={['fas', 'check-square'] }/></span>
												<AcceptStatuses checked={task.done ? 1 : 0}/>
											</span>
										</div>
										<div className="col-sm-3">
											<span>
												<span style={{paddingRight:"5px"}}><FontAwesomeIcon style={{}} alt="Applikációból feltöltve" size="1x" icon={['fas', 'building'] }/>
												</span><AcceptStatuses checked={task.approved ? 1 : 0}/>
											</span>
										</div>
									</div>
								</>
										:
										<div className=" row project-details-map">
											{/* <button onClick={() => props.zoomToFeature(feature)} ><FontAwesomeIcon size="1x" icon={['far', 'search-plus'] } />Közelit</button> */}
										</div>

									}
								{liveData &&
								<div>
									<p style={{margin: "5px", fontSize: "14px"}}>Jelenlegi adatok</p>
									{console.log('livedata', liveData)}
									<table>
										<tbody>
											{Object.entries(liveData).map(([key, value]) => {
												return getLiveData(key, value)
											})}
										</tbody>
									</table>
								</div>
								}
								{liveData && <p style={{margin: "5px", fontSize: "14px"}}>Általános adatok</p>}
								<table>
									<tbody>
										<tr>
											<td style={{fontSize: "11px", fontWeight:"600"}}>ID</td>
											<td>{props.data.values_.id}</td>
										</tr>
										{loadData()}
									</tbody>
								</table>
								{edit ?
								<div style={{textAlign:"center"}}>
									<button className="map-details-edit-button" onClick={() => updateTasks()} disabled={!Object.keys(selected).length}>Mentés</button>
									<button className="map-details-edit-button" onClick={() => setEdit(false)}>Vissza</button>
								</div> : null}
								{feedback.message !== "" &&
								<div style={{textAlign:'center', fontSize:'12px', color:feedback.color, margin:'10px'}}>
									<p>{feedback.message}</p>
								</div>}
								</div>
							</div>
						</div>
					{images.length && !edit ?
					<>
						<div className="col-md-4 pl-0">
							<div className="images">
								<div className="image">
									{/* <h5>Képek</h5> */}
									<div><p className='cadastre-label-map-details'>Képek</p></div>
									<div className="img-grid">
										{images.map((image, index) => (
											<div key={index} onClick={() => setPopup({
													visible: true,
													imageUrl: image,
													imageIndex: index,
													note: ''
												})} className="img" style={{backgroundImage: `url(${image})`}} />
										))}
									</div>
								</div>
							</div>
						</div>
						<Modal className="confirm" show={popup.visible} onHide={() => setPopup({visible: false, imageId: ''})} aria-labelledby="contained-modal-title-vcenter" centered>
							<Modal.Body>
								<div className='map-carousel-zoom'>
									<label htmlFor="zoom">Zoom: </label>
									<select id="zoom" value={zoomLevel} onChange={handleZoomChange}>
										{zoomLevels.map((level) => (
										<option key={level} value={level}>
											{level} %
										</option>
										))}
									</select>
								</div>
								<Carousel className='map-carousel' activeIndex={popup.imageIndex} onSelect={(selectedIndex) => setPopup({...popup, imageIndex: selectedIndex})}>
									{images.map((image, index) => (
										<Carousel.Item key={index}>
											<ImageZoom 
												className="d-block w-100"
												src={image}
												alt={`Image ${index + 1}`}
												zoom={zoomLevel}
											/> 
										</Carousel.Item>
									))}
								</Carousel> 
								{popup.note !== '' ? <p style={{padding:"10px"}}>Megjegyzés: {popup.note}</p> : null}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="danger" onClick={() => {setPopup({visible: false, imageId: ''}); setZoomLevel(300)}}>Bezár</Button>
							</Modal.Footer>
						</Modal>
					</>
					:
					null
					}
					</div>
				</div>
			</div>
		</>
		);

}

const mapStateToProps = state => ({
	cadastreData: getUserCadastreData(state)
});

export default connect(
  mapStateToProps,
  null,
)(MoreDetails);
