import React, { useEffect, useState, useRef } from 'react';
import { KataszterController } from '../../controllers/KataszterController';
import KataszterDataTable from './elements/kataszerDataTable';
import {setupTable} from './_helper/tableCreator';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveCadastreFilterContainer from "../../components/Map/ui/ActiveCadastreFilterContainer";
import KataszterUpdateModal from './elements/kataszterUpdateModal';
import CadastreFilterComponent from '../Project/sidebar/CadastreFilterComponent';
import { getMapLayerOptions } from '../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import useWindowDimensions from '../../helpers/useWindowDimensions';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

function Kataszter(props) {

    const KataszterControllerInst = KataszterController.getInstance();
    const [kataszterData, setKataszterData] = useState([]);
    const [total, setTotal] = useState(0);
    const [columns, setColumns] = useState([]);

    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const [objectChanged, setObjectChanged] = useState(false);  // eslint-disable-line

    const [sortByData, setSortByData] = useState({});

    const [show, setShow] = useState(false);

    const [id, _setId] = useState(null);
	const idRef = React.useRef(id);
	const setId = data => {
		idRef.current = data;
		_setId(data);
	};
    const prevId = usePrevious(id);

    const [dataLoaded, setDataLoaded] = useState(false);

    const [tableExpanded, _setTableExpanded] = useState({});
	const tableExpandedRef = React.useRef(tableExpanded);
	const setTableExpanded = data => {
		tableExpandedRef.current = data;
		_setTableExpanded(data);
	};
    
    const { height, width } = useWindowDimensions();  // eslint-disable-line

    //force update on api save
    useEffect(() => {
        if((prevId !== null && prevId !== undefined) && id === null) {
            getData();
        }
    }, [id])  // eslint-disable-line

    useEffect(() => {
        if(props?.cadastre?.form_json) {
            if(page > 0) {
                getData();
            }
            setObjectChanged(false);
            setColumns(setupTable(props.cadastre.form_json, update, idRef, width));
        }
    }, [page, pageSize, props.layerOptions.searchFilters, sortByData, width]);  // eslint-disable-line

    function getData() {

        if(props?.cadastre?.cadastre_id) {

            let modifiedFilters = [];

            //Refactor filters to work with controller
            Object.keys(props.layerOptions.searchFilters).forEach(key => {
                Object.entries(props.layerOptions.searchFilters[key]).forEach(([filterKey, filterValue] )=> {
                    modifiedFilters.push({type:"cadastre", id:key, name:filterKey, value:filterValue, label:""})

                })
            });

            KataszterControllerInst.getCadastreSpots([props.cadastre.cadastre_id], page, pageSize, modifiedFilters, false, sortByData).then(res => {
                if(res.statusCode === 200) {
                    setKataszterData(res.data.data)
                    setTotal(res.data.total);
                    setDataLoaded(true);
                }
                else {
                    console.log("Error: ", res.data);
                    setDataLoaded(true);
                }
            }).catch(err => {
                console.log("er",err)
                setDataLoaded(true);
            });
        }
    }

    function update(taskid, row) {
        if(taskid == id) {  // eslint-disable-line
            setId(null)
        }else {
            setId(taskid);
        }
        let temp = {};
        temp[row.id] = true;

        if(!row.isExpanded) {
            setTableExpanded({...tableExpanded, ...temp})
        }
    }

    function onChangeSort(data) {
        if(data !== undefined) {
            let dataName = props.cadastre.form_json.find(x => x.label === data.id).name
            setSortByData({name:dataName, desc:data.desc})
            //future sorting
        }
    }

    return(
        <div className='kataszter-view'>
            <ActiveCadastreFilterContainer />
            <Row>
                    <Col lg={4} xs={12}>
                        <div className="kataszter-top">
                            <ul className="nav nav-tabs">
                                <li className="back-button"><a role="button" onClick={() => props.setDetailed(null)}><i><FontAwesomeIcon size="lg" icon={['fas', 'chevron-left']} /></i>Vissza</a></li>
                            </ul>
                            <p style={{marginLeft:'10px'}}>{props?.cadastre?.cadastre_label && props.cadastre.cadastre_label}</p>
                        </div>
                    </Col>
                    <Col lg={3} xs={12}>
                        <div className="items-per-page">
                            <span>Találat oldalanként</span>
                            <select id="pagenumber" defaultValue={25} name="pagenumber" onChange={(e) => {setPageSize(Number(e.target.value))}}>
                                <option value="25">25</option>
                                <option value="50" >50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg={3} xs={12}>
                        <div className="pages">
                            <Button variant="light" id="grantButton" onClick={() => setPage(1)}><FontAwesomeIcon icon={['fas', 'step-backward']} /></Button>
                            <Button variant="light" id="grantButton" onClick={() => page !== 1 ? setPage(page - 1) : setPage(page)}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></Button>
                            <span>{page} of {Math.ceil(total/pageSize)}</span>
                            <Button variant="light" id="grantButton" onClick={() => page !== Math.ceil(total/pageSize) ? setPage(page + 1) : setPage(page)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></Button>
                            <Button variant="light" id="grantButton" onClick={() => setPage(Math.ceil(total/pageSize))}><FontAwesomeIcon icon={['fas', 'step-forward']} /></Button>
                        </div>
                    </Col>
            </Row>
            <Row>
                <Col lg={9}>
                    {!dataLoaded ? 
                    <div className="loader-container">
                        <div className="loader" style={{margin:"auto"}}/>
                    </div> : null}

                    {(kataszterData.length) ?
                        <KataszterDataTable 
                            cadastre_details={props.cadastre.form_json} 
                            tableExpanded={tableExpandedRef.current} 
                            setTableExpanded={setTableExpanded} 
                            columns={columns} 
                            data={kataszterData} 
                            kataszterId={props.cadastre.id} 
                            onChangeSort={onChangeSort} 
                            updateId={id} 
                            updateSelected={setId}
                            setObjectChanged={setObjectChanged}
                        /> 
                    : 
                        <p style={{marginLeft:'40%'}}>{!dataLoaded ? 'Kérem, várjon az adatok betöltésére.'  :'Nem található a kért adat.'}</p>
                    }
                </Col>
                <Col lg={3} className="kataszter-filter">
                    <CadastreFilterComponent cadastre={props.cadastre} modifyStyle={false} header={false}/>
                </Col>
            </Row>
            {show && <KataszterUpdateModal show={show} setShow={setShow} rowid={id} kataszterId={props.cadastre.id} setObjectChanged={setObjectChanged}/>}
        </div>
    )
}

const mapStateToProps = state => ({
    layerOptions: getMapLayerOptions(state),
});

const mapDispatchToProps = () => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Kataszter);

